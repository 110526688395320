var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("property-info", {
        attrs: {
          property: _vm.property,
          company: _vm.company,
          email: _vm.email
        }
      }),
      _vm.showDisclosures
        ? _c("property-disclosures", {
            attrs: { disclosure: _vm.disclosure, sections: _vm.sections }
          })
        : _vm._e(),
      _vm.showPropertyMeta
        ? _c("property-meta", { attrs: { meta: _vm.meta } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }