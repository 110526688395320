<template>
    <div>
        <property-info
            :property="property"
            :company="company"
            :email="email"
        ></property-info>
        <!-- TODO: Add Locale Support For Property Disclosure -->
        <property-disclosures
            :disclosure="disclosure"
            :sections="sections"
            v-if="showDisclosures"
        ></property-disclosures>
        <property-meta :meta="meta" v-if="showPropertyMeta"></property-meta>
    </div>
</template>
<script>
export default {
    name: "property-details",
    components: {
        PropertyInfo: () => import("./PropertyInfo"),
        PropertyDisclosures: () => import("./PropertyDisclosures"),
        PropertyMeta: () => import("./PropertyMeta"),
    },
    props: {
        application: {
            type: Object,
            required: true,
        },
        applicant: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        property() {
            return this.application.property;
        },
        company() {
            return this.application.company;
        },
        disclosure() {
            return this.application.meta.content.disclosure;
        },
        email() {
            return this.application.property.contact.email;
        },
        meta() {
            return this.application.meta;
        },
        sections() {
            return this.applicant.sections;
        },
        showDisclosures() {
            // determine if the disclosures slug is enabled for the setup section, default is to show disclosures. We disable for staff to avoid
            // additional unneccessary content display
            return this.$_.get(this.applicant, "sections.disclosures", true);
        },
        showPropertyMeta() {
            return this.$_.get(
                this.applicant,
                "sections.property-rental-meta",
                true
            );
        },
    },
    mounted() {
        this.$emit("update", { complete: true });
    },
    created() {},
    updated() {},
};
</script>

<style >
#pca-disclosure > h4 {
    margin-bottom: 10px;
}
</style>